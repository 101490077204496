@import "styles/_Common.scss";
.content {
    max-width: 1440px;
    margin: 0 auto;
    & > .register {
        width: 100%;
        position: relative;
        & > .register-left {
            float: left;
            min-height: 805px;
            width: 100%;
            background: url("../../../assets/images/login_background.png");
            background-position: right;
            background-repeat: no-repeat;
            margin-left: -50%;
            & > h1 {
                font-family: Noto Sans KR;
                margin-left: calc(100% - 351px);
                margin-top: 74px;
                font-size: 28px;
                font-weight: bolder;
                line-height: 41px;
                color: #323232;
                & > span {
                    font-family: Noto Sans KR;
                    font-size: 28px;
                    font-weight: border;
                    color: #fff;
                }
            }

            & > p {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 26px;
                color: #323232;
                margin-left: calc(100% - 351px);
                margin-top: 17px;
            }
        }
        & > .register-right {
            float: right;
            min-height: 805px;
            width: 50%;
            & > div {
                text-align: center;
                width: 352px;
                margin-left: 112px;
                margin-top: 89px;
                & > h1 {
                    font-size: 24px;
                    line-height: 35px;
                    margin-bottom: 6px;
                }
                & > form {
                    .email_box {
                        position: relative;
                        & > .email_check {
                            width: 352px;
                            border: 1px solid #e0e0e0;
                            margin: 8px 0;
                            height: 40px;
                            font-size: 13px;
                            padding-left: 8px;
                        }

                        & > .email_check::placeholder {
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.3);
                        }
                        & > button {
                            position: absolute;
                            height: 32px;
                            width: 85px;
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            background: #4f4f4f;
                            border-radius: 3px;
                            color: #fff;
                            top: 12px;
                            right: 4px;
                        }
                    }

                    & > input::placeholder {
                        font-size: 13px;
                        color: rgba(0, 0, 0, 0.3);
                    }
                    & > input,
                    select {
                        width: 352px;
                        border: 1px solid #e0e0e0;
                        margin: 8px 0;
                        height: 40px;
                        font-size: 13px;
                        padding-left: 8px;
                        & > option[data-default] {
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.3);
                        }
                    }
                    & > select {
                        -webkit-appearance: none;
                        background: url("../../../assets/images/select_arrow.png")
                            no-repeat 95% 50%;
                    }
                    & > .genders {
                        & > label {
                            margin: 8px 0;
                            display: inline-block;
                            & > input[type="radio"] {
                                display: none;
                            }
                            & > input[type="radio"]:checked ~ .gender-box {
                                background: #16bdc6;
                                border-color: #16bdc6;
                                border-radius: 0px 3px 3px 0px;
                                color: #fff;
                                transition: 0.5s all;
                            }
                            & > .gender-box {
                                background-color: #fff;
                                border-radius: 3px;
                                font-size: 13px;
                                line-height: 40px;
                                width: 175px;
                                height: 40px;
                                text-align: center;
                                border: 1px solid #e0e0e0;
                                transition: 0.5s all;
                            }
                        }
                    }
                    & > p {
                        text-align: left;
                        margin-bottom: 31px;
                        font-size: 13px;
                        font-weight: 300;
                        color: #323232;
                    }
                    & > label {
                        display: table;
                        & > input {
                            display: inline-block;
                            vertical-align: middle;
                            border: 1px solid #e0e0e0;
                            width: 16px;
                            height: 16px;
                            margin-right: 10px;
                            background-color: #fff;
                        }
                        color: #323232;
                        font-size: 13px;
                        font-weight: 300;
                    }

                    & > .notice-agreement {
                        margin: 12px 0;
                        float: left;
                        vertical-align: middle;
                        & > label {
                            margin-right: 10px;
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            border: 1px solid #e0e0e0;
                            background-color: transparent;
                            vertical-align: bottom;
                            text-align: center;
                            line-height: 16px;
                            & > input[type="checkbox"] {
                                display: none;
                            }
                        }

                        & > span {
                            line-height: 19px;
                            display: inline-block;
                            font-family: Noto Sans KR;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            vertical-align: middle;
                        }
                    }

                    & > input[type="submit"] {
                        background: #4f4f4f;
                        width: 352px;
                        height: 48px;
                        text-align: center;
                        line-height: 48px;
                        color: #fff;
                    }

                    & > div:last-child {
                        padding-top: 24px;

                        & > span {
                            margin-right: 165px;
                            font-size: 12px;
                        }
                        & > a {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@include media("<=medium", ">=small") {
    .content {
        max-width: 786px;
        min-width: auto;
        margin: 0 20px;

        & > .register {
            width: 95%;
            position: relative;

            & > .register-left {
                float: left;
                display: none;
                min-height: 805px;
                width: 50%;
                background: url("../../../assets/images/login_background.png");
                background-position: right;
                & > h1 {
                    margin-left: 352px;
                    margin-top: 74px;
                    font-size: 28px;
                    font-weight: bolder;
                    line-height: 41px;
                    color: #323232;
                    & > span {
                        font-size: 28px;
                        font-weight: bold;
                    }
                }

                & > p {
                    font-size: 18px;
                    line-height: 26px;
                    color: #323232;
                    margin-left: 352px;
                    margin-top: 17px;
                }
            }
            & > .register-right {
                float: none;
                min-height: 585px;
                width: 100%;
                display: flex;
                justify-content: center;
                & > div {
                    text-align: center;
                    width: 100%;
                    margin-left: 0px;
                    margin-top: 0px;

                    & > h1 {
                        font-size: 24px;
                        line-height: 35px;
                        margin-bottom: 6px;
                        display: block;
                    }
                    & > form {
                      width: 100%;
                        .email_box {
                          width: 100%;
                            position: relative;
                            & > .email_check {
                                width: 100%;
                                border: 1px solid #e0e0e0;
                                margin: 8px 0;
                                height: 40px;
                                font-size: 13px;
                                padding-left: 8px;
                            }

                            & > .email_check::placeholder {
                                font-size: 13px;
                                color: rgba(0, 0, 0, 0.3);
                            }
                            & > button {
                                position: absolute;
                                height: 32px;
                                width: 85px;
                                font-family: Noto Sans KR;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 13px;
                                background: #4f4f4f;
                                border-radius: 3px;
                                color: #fff;
                                top: 12px;
                                right: 4px;
                            }
                        }

                        & > input::placeholder {
                            font-size: 13px;
                            color: rgba(0, 0, 0, 0.3);
                        }
                        & > input,
                        select {
                            width: 100%;
                            border: 1px solid #e0e0e0;
                            margin: 8px 0;
                            height: 40px;
                            font-size: 13px;
                            padding-left: 8px;
                            & > option[data-default] {
                                font-size: 13px;
                                color: rgba(0, 0, 0, 0.3);
                            }
                        }
                        & > select {
                            -webkit-appearance: none;
                            background: url("../../../assets/images/select_arrow.png")
                                no-repeat 95% 50%;
                        }
                        & > .genders {
                          width: 100%;
                            & > label {
                                margin: 8px 0;
                                display: inline-block;
                                width: 45%;
                                border-radius: 3px;
                                & > input[type="radio"] {
                                    display: none;
                                }
                                & > input[type="radio"]:checked ~ .gender-box {
                                    background: #16bdc6;
                                    border-color: #16bdc6;
                                    border-radius:3px;
                                    color: #fff;
                                    transition: 0.5s all;
                                }
                                & > .gender-box {
                                    background-color: #fff;
                                    border-radius: 3px;
                                    font-size: 13px;
                                    line-height: 40px;
                                    width: 100%;
                                    height: 40px;
                                    text-align: center;
                                    border: 1px solid #e0e0e0;
                                    transition: 0.5s all;
                                }
                                &:nth-child(2){
                                  margin-left: 10%;
                                }
                            }
                        }
                        & > p {
                            width: 90%;
                            text-align: left;
                            margin-bottom: 31px;
                            font-size: 13px;
                            font-weight: 300;
                            color: #323232;
                            margin-left: 30px;
                        }
                        & > label {
                            display: inline-block;
                            & > input {
                                display: inline-block;
                                vertical-align: middle;
                                border: 1px solid #e0e0e0;
                                width: 16px;
                                height: 16px;
                                margin-right: 10px;
                                background-color: #fff;
                            }
                            color: #323232;
                            font-size: 13px;
                            font-weight: 300;
                        }

                        & > .notice-agreement {
                            margin: 12px 0;
                            float: left;
                            vertical-align: middle;
                            & > label {
                                margin-right: 10px;
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                border: 1px solid #e0e0e0;
                                background-color: transparent;
                                vertical-align: bottom;
                                text-align: center;
                                line-height: 16px;
                                & > input[type="checkbox"] {
                                    display: none;
                                }
                            }
    
                            & > span {
                                line-height: 19px;
                                display: inline-block;
                                font-family: Noto Sans KR;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 13px;
                                vertical-align: middle;
                            }
                        }

                        & > input[type="submit"] {
                            background: #4f4f4f;
                            width: 100%;
                            height: 48px;
                            text-align: center;
                            line-height: 48px;
                            color: #fff;
                        }

                        & > div:last-child {
                            padding-top: 24px;
                            display: none;

                            & > span {
                                margin-right: 165px;
                                font-size: 12px;
                            }
                            & > a {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
