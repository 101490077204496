@import "styles/_Common.scss";
// 서울시 청년주택의 카테고리
.sub-category {
  position: absolute;
  width: 100%;
  max-width: 1920px;
  min-width: 1120px;
  left: 0;
  margin-top: -33px;
  text-align: center;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #e0e0e0;
  & > ul {
    width: 1120px;
    text-align: center;
    margin: 0 auto;
    height: 56px;
    & > li {
      display: inline-block;
      width: 25%;
      line-height: 56px;
      height: 56px;

      & > button {
        width: 100%;
        display: block;
        font-family: Noto Sans KR;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        height: 56px;
        color: rgba(0, 0, 0, 0.3);
      }
      & > button.primary_blue {
        border-bottom: 3px solid #16bdc6;
        color: #16bdc6;
      }
    }
  }
}

// 서울시 청년주택
.dictionary-box {
  width: 1152px;
  max-width: 1440px;
  min-width: 1024px;
  margin : 0 auto;
  & > .dictionary-contents {
    width: 100%;
    margin: 80px auto 120px;
    & > .contents-list {
      width: 100%;
      margin: 0 auto;
      & > h2 {
        position: relative;
        padding-top: 73px;
        padding-bottom: 23px;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        & > span {
          color: #bdbdbd;
          font-size: 20px;
          line-height: 29px;
        }
        & > a {
          font-size: 14px;
          line-height: 20px;
          color: #828282;
          position: absolute;
          right: 50px;
          bottom: 23px;
        }
      }

      // 청년주택의 모든 것
      & > .y-house-comics:first-child {
        margin-top: 0;
      }
      & > .y-house-comics {
        position: relative;
        margin-top: 32px;
        & > div {
          display: flex;
          flex-wrap: wrap;
          & > div:first-child {
            margin-left: 16px;
            margin-right: 32px;
          }
          & > div:hover {
            background: #f2f2f2;
          }
          & > div {
            max-width: 543px;
            width: 100%;
            border: 1px solid #e0e0e0;
            cursor: pointer;
            & > .comic-avata {
              width: 192.8px;
              height: 100%;
              float: left;
              & > img {
                height: 100%;
                max-width: 100%;
              }
            }
            & > .comic-info {
              width: calc(100% - 192.8px);
              height: 100%;
              float: right;
              padding: 22px 0;
              padding-left: 25px;
              & > h4 {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 20px;
              }
              & > p {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 25px;
              }
              & > .publisher {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                color: #828282;
                & > span {
                  display: inline-block;
                  height: 22px;
                  line-height: 22px;
                  overflow: hidden;
                  margin-right: 6px;
                  vertical-align: middle;
                  & > img {
                    display: inline-block;
                    text-align: center;
                  }
                }
              }
              & > .card-footer {
                width: 100%;
                margin-top: 9px;
                & > span {
                  font-family: IBM Plex Sans;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 16px;
                  vertical-align: middle;
                  display: inline-block;
                  margin-right: 9px;
                  color: #828282;
                  & > img {
                    margin-right: 2px;
                    vertical-align: middle;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
      // 청년임대주택 알아보기
      & > .y-house-card-items {
        position: relative;
        & > div {
          display: flex;
          flex-wrap: wrap;
          & > .third-cols:hover {
            background: #f2f2f2;
          }
          & > .third-cols {
            // text-align: center;
            width: 352px;
            max-width: 543px;
            // width: 100%;
            height: 378px;
            margin: 0px 16px 40px 16px;
            overflow: hidden;
            // border: 1px solid #e0e0e0;
            cursor: pointer;
            & > .image-cover {
              width: 352px;
              // width: 192.8px;
              // height: 192px;
              height: 264px;
              overflow: hidden;
              border-radius: 2px;
              margin-bottom: 24px;
              // border: 1px solid #e0e0e0;
              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            & > .leftSide {
              width:100%;
              // padding: 22px 0;
              & > h3 {
                text-align: center;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                height: 30px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              & > h4 {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 30px;
                overflow: hidden;
              }
              & > .card-footer {
                width: 100%;
                // text-align: center;
                height: 24px;
                text-align: center;
                & > span {
                  font-family: IBM Plex Sans;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 16px;
                  vertical-align: middle;
                  margin-right: 9px;
                  color: #828282;
                  & > img {
                    margin-right: 2px;
                    vertical-align: middle;
                    margin-right: 5px;
                  }
                }
              }
            }
            & > .publisher {
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              height: 24px;
              line-height: 22px;
              & > span {
                display: inline-block;
                line-height: 22px;
                height: 22px;
                overflow: hidden;
                margin-right: 6px;
                vertical-align: middle;
                & > img {
                  display: inline-block;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}


@include media("<=medium") {
  .dictionary-wrap {
    display: flex;
    justify-content: center;
  }
  .dictionary-contents {
    width: 100%;
    padding: 0 16px;
    & > .contents-list {
      max-width: 430px;
      margin: 0 auto;

      & > h2 {
        position: relative;
        padding-top: 73px;
        padding-bottom: 23px;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        & > span {
          color: #bdbdbd;
          font-size: 20px;
          line-height: 29px;
        }
        & > a {
          font-size: 14px;
          line-height: 20px;
          color: #828282;
          position: absolute;
          right: 50px;
          bottom: 23px;
        }
      }
      & > .houselife-items {
        margin-bottom: 63px;
        position: relative;

        & > div {
          width: 320px;
          display: flex;
          flex-wrap: wrap;
          & > .third-cols:nth-child(2) {
            margin: 0 32px;
          }
          & > .third-cols {
            text-align: center;
            max-width: 352px;

            & > h3 {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 13px;
            }
            & > .image-cover {
              width: 352px;
              height: 264px;
              overflow: hidden;
              border-radius: 2px;
              margin-bottom: 24px;
            }
            & > .publisher {
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              margin-bottom: 8px;
              & > .profile {
                width: 22px;
                height: 22px;
                overflow: hidden;
                border: 50%;
                display: inline-block;
                margin-right: 6px;
                vertical-align: middle;
                & > img {
                  text-align: center;
                }
              }
            }

            & > .card-footer {
              width: 100%;
              text-align: center;
              & > span {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                vertical-align: middle;
                margin-right: 9px;
                & > img {
                  margin-right: 2px;
                  vertical-align: middle;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
      & > .comics {
        position: relative;
        & > .comics-content-wrap {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          & > .comic-avata {
            width: 36%;
            height: 144px;
            float: left;
            & > img {
              height: 100%;
              max-width: 100%;
            }
          }
          &:nth-child(1) ~ * {
            margin-top: 20px;
          }

          &:nth-child(2n) {
            margin-left: 0;
          }
          & > .comic-info {
            position: unset;
            width: calc(100% - 154.64px);
            height: 100%;
            float: right;
            padding: 14px 12px;
            & > h4 {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 10px;
            }
            & > p {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.65);
              margin-bottom: 8px;
              overflow: hidden;
              width: 90%;
              height: 34px;
            }
            & > .publisher {
              display: none;
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              line-height: 16px;
              & > .profile {
                width: 22px;
                height: 22px;
                overflow: hidden;
                border: 50%;
                display: inline-block;
                margin-right: 6px;
                vertical-align: middle;
                & > img {
                  text-align: center;
                }
              }
            }
            & > .card-footer {
              width: 100%;
              margin-top: 0px;
              bottom: 20px;
              & > span {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                vertical-align: middle;
                display: inline-block;
                margin-right: 9px;
                & > img {
                  margin-right: 2px;
                  vertical-align: middle;
                  margin-right: 5px;
                }
              }
              & > span:first-child {
                display: block;
                margin-bottom: -6px;
              }
            }
          }
        }
      }

      & > .story {
        & > div {
          display: flex;
          flex-wrap: wrap;
          & > .story-card:last-child {
            margin-right: 0;
          }
          & > .story-card {
            max-width: 256px;
            width: 100%;
            margin-right: 32px;
            & > .card-image {
              margin-bottom: 17px;
              & > div {
                width: 100%;
                height: 100%;
                & > img {
                  width: 100%;
                  height: auto;
                }
              }
            }
            & > .card-title {
              font-weight: 500;
              font-size: 16px;
              line-height: 23px;
              margin-bottom: 10px;
            }

            & > .card-footer {
              width: 100%;
              & > span {
                font-weight: 300;
                font-size: 12px;
                vertical-align: middle;
                line-height: 16px;
                & > img {
                  margin-right: 2px;
                }
              }
              & > span:first-child {
                margin-right: 90px;
              }
              & > span:last-child {
                margin-left: 14px;
              }
            }
          }
        }
      }
      & > .houselife-report {
        position: relative;
        & > div {
          display: flex;
          flex-wrap: wrap;
          & > .third-cols:nth-child(2) {
            margin: 0 32px;
          }
          & > .third-cols {
            text-align: center;
            max-width: 352px;

            & > h3 {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 13px;
            }
            & > .image-cover {
              width: 352px;
              height: 264px;
              overflow: hidden;
              border-radius: 2px;
              margin-bottom: 24px;
            }
            & > .publisher {
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              margin-bottom: 8px;
              & > .profile {
                width: 22px;
                height: 22px;
                overflow: hidden;
                border: 50%;
                display: inline-block;
                margin-right: 6px;
                vertical-align: middle;
                & > img {
                  text-align: center;
                }
              }
            }

            & > .card-footer {
              width: 100%;
              text-align: center;
              & > span {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                vertical-align: middle;
                margin-right: 9px;
                & > img {
                  margin-right: 2px;
                  vertical-align: middle;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
      & > .young-house {
        position: relative;
        & > div {
          display: flex;
          flex-wrap: wrap;
          & > div:first-child {
            margin-right: 34px;
          }
          & > div {
            max-width: 543px;
            width: 100%;
            border: 1px solid #e0e0e0;
            & > .comic-avata {
              width: 192.8px;
              height: 100%;
              float: left;
              & > img {
                height: 100%;
                max-width: 100%;
              }
            }
            & > .comic-info {
              width: calc(100% - 192.8px);
              height: 100%;
              float: right;
              padding: 22px 0;
              padding-left: 25px;
              & > h4 {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 20px;
              }
              & > p {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 25px;
              }
              & > .publisher {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                & > .profile {
                  width: 22px;
                  height: 22px;
                  overflow: hidden;
                  border: 50%;
                  display: inline-block;
                  margin-right: 6px;
                  vertical-align: middle;
                  & > img {
                    text-align: center;
                  }
                }
              }
              & > .card-footer {
                width: 100%;
                margin-top: 9px;
                & > span {
                  font-family: IBM Plex Sans;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 16px;
                  vertical-align: middle;
                  display: inline-block;
                  margin-right: 9px;
                  & > img {
                    margin-right: 2px;
                    vertical-align: middle;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
      & > .young-house-info {
        position: relative;
        & > div {
          display: flex;
          flex-wrap: wrap;
          & > .third-cols:nth-child(2) {
            margin: 0 32px;
          }
          & > .third-cols {
            text-align: center;
            max-width: 352px;

            & > h3 {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              margin-bottom: 13px;
            }
            & > .image-cover {
              width: 352px;
              height: 264px;
              overflow: hidden;
              border-radius: 2px;
              margin-bottom: 24px;
            }
            & > .publisher {
              font-family: IBM Plex Sans;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              margin-bottom: 8px;
              & > .profile {
                width: 22px;
                height: 22px;
                overflow: hidden;
                border: 50%;
                display: inline-block;
                margin-right: 6px;
                vertical-align: middle;
                & > img {
                  text-align: center;
                }
              }
            }

            & > .card-footer {
              width: 100%;
              text-align: center;
              & > span {
                font-family: IBM Plex Sans;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                vertical-align: middle;
                margin-right: 9px;
                & > img {
                  margin-right: 2px;
                  vertical-align: middle;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .y-house-card-items > div > .third-cols {
    margin: 0 auto 40px !important;
  }
}