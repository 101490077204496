@import "styles/_Common.scss";
@import "styles/_Animation";

.wrap {
  width: 1120px;

  margin: 0 auto;
  padding: 40px 0;

  display: flex;
  align-items: flex-start;
}

.left {
  width: 736px;
}

.right {
  flex: 1;

  padding-left: 30px;
}

.slide {
  width: 100%;
  height: 500px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #dee2e6;

  & > img {
    width: 80px;
    height: 80px;

    animation: rotate-center 0.5s linear infinite both;
  }
}

.fixed-info {
  flex: 1;

  background-color: #fff;
  & > .tag-box {
    & > span {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  & > h1 {
    font-weight: 500;
    font-size: 22px;
    border-bottom: 1px solid #e0e0e0;
    width: 344px;
    border-bottom: 1px solid #e0e0e0;
    margin-top: 10px;
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
  & > p {
    font-size: 14px;
  }
  & > form {
    & > select {
      margin: 24px 0;
      width: 100%;
      height: 36px;
      background-color: #ffffff;
      border: 1px solid #e0e0e0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);
    }
    & > input {
      background: #4f4f4f;
      border-radius: 4px;
      height: 48px;
      width: 100%;
      color: #ffffff;
      text-align: center;
    }
  }
  & > .util-buttons {
    position: relative;
    margin-top: 48px;
    margin-bottom: 100px;
    & > div {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      & > img {
          width: 22px;
          height: 22px;
          object-fit: contain;
          &.share{
            width: 28px;
            height: 28px;
          }
        }
    }
    & > div:nth-child(3):hover ~ .hover-util {
      width: 100%;
      transition: 0.5s all;
      border: 1px solid #e0e0e0;
    }
    & > .hover-util {
      border: none;
      transition: 0.5s all;
      background-color: #ffffff;
      position: absolute;
      max-width: 240px;
      width: 0%;
      overflow: hidden;
      left: 144px;
      & > button {
        margin: 0 11.5px;
        & > img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
      }
    }
    & > .hover-util:hover {
      border: 1px solid #e0e0e0;
      width: 100%;
    }
    & > div:not(last-child) {
      margin-right: 8px;
    }
    & > button {
      float: right;
      width: 104px;
      height: 36px;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      border-radius: 2px;
      & > img {
        margin-right: 10px;
        width: 28px;
        height: 28px;
        object-fit: contain;
      }
    }
  }
  & > .util-buttons::after {
    content: "";
    display: block;
    clear: both;
  }
}

@include media("<=medium", ">=small") {
  .wrap {
    display: block;
    width: 100%;
    padding: unset;
    margin: unset;
    align-items: unset;
  }
  .left {
    width: 100%;

    & > .slide {
      height: 270px;
    }
  }

  .right {
    flex: unset;

    padding-left: unset;
  }

  .fixed-info {
    flex: unset;

    & > h1 {
      margin-bottom: 10px;
      padding-bottom: 28px;
    }

    & > form {
      input {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 999;
      }
    }

    & > .util-buttons {
      margin-top: 0;
      margin-bottom: 200px;
    }
  }
}
