@import "~include-media/dist/include-media";

$breakpoints: (
  small: 320px,
  medium: 768px,
  large: 1024px
);
input, button {
  -webkit-appearance: none !important;
}

// $primary: (
//     color: white,
//     background: #0b245d
// );
// $primary-invert: (
//     color: #0b245d,
//     background: white
// );

// $button: (
//     backgorund: #f3f3f3
// );
