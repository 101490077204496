@import "styles/_Common.scss";
.content {
    max-width: 1440px;
    margin: 0 auto;
    & > .register {
        width: 100%;
        position: relative;
        & > .register-left {
            float: left;
            min-height: 805px;
            width: 100%;
            background: url("../../../assets/images/login_background.png");
            background-repeat: no-repeat;
            background-position: right;
            margin-left: -50%;
            & > h1 {
                font-family: Noto Sans KR;
                margin-left: calc(100% - 351px);
                margin-top: 74px;
                font-size: 28px;
                font-weight: bolder;
                line-height: 41px;
                color: #323232;
                & > span {
                    font-family: Noto Sans KR;
                    font-size: 28px;
                    font-weight: border;
                    color: #fff;
                }
            }

            & > p {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 26px;
                color: #323232;
                margin-left: calc(100% - 351px);
                margin-top: 17px;
            }
        }
        & > .register-right {
            float: right;
            min-height: 805px;
            width: 50%;
            & > div {
                text-align: center;
                width: 352px;
                margin-left: 112px;
                margin-top: 89px;
                & > span {
                    display: block;
                    font-size: 24px;
                    line-height: 35px;
                    margin-top: 168px;
                    margin-bottom: 22px;
                }
                & > h1 {
                    font-size: 36px;
                    line-height: 35px;
                    margin-bottom: 14px;
                }

                & > p {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 160%;
                }

                & > a {
                    display: block;
                    background-color: #4f4f4f;
                    border-radius: 3px;
                    height: 48px;
                    width: 352px;
                    color: #fff;
                    text-align: center;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 48px;
                    margin-top: 70px;
                }
            }
        }
    }
}
@include media("<=medium", ">=small") {
  .content {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    & > .register {
        width: 100%;
        position: relative;
        & > .register-left {
            float: left;
            min-height: 805px;
            width: 100%;
            background: url("../../../assets/images/login_background.png");
            background-repeat: no-repeat;
            background-position: right;
            margin-left: -50%;
            display: none;
            & > h1 {
                font-family: Noto Sans KR;
                margin-left: calc(100% - 351px);
                margin-top: 74px;
                font-size: 28px;
                font-weight: bolder;
                line-height: 41px;
                color: #323232;
                & > span {
                    font-family: Noto Sans KR;
                    font-size: 28px;
                    font-weight: border;
                    color: #fff;
                }
            }

            & > p {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 26px;
                color: #323232;
                margin-left: calc(100% - 351px);
                margin-top: 17px;
            }
        }
        & > .register-right {
            float: none;
            min-height: auto;
            width: 95%;
            margin : 0 auto;
            padding : 40px 0;
            & > div {
                text-align: center;
                width: 100%;
                margin-left: 0;
                margin-top: 0;
                & > span {
                    display: block;
                    font-size: 18px;
                    line-height: 35px;
                    margin-top: 0;
                    margin-bottom: 22px;
                }
                & > h1 {
                    font-size: 24px;
                    line-height: 35px;
                    margin-bottom: 14px;
                }

                & > p {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 160%;
                }

                & > a {
                    display: block;
                    background-color: #4f4f4f;
                    border-radius: 3px;
                    height: 48px;
                    width: 100%;;
                    color: #fff;
                    text-align: center;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 48px;
                    margin-top: 70px;
                }
            }
        }
    }
}
}