@import "styles/_Common.scss";

.footer {
    margin: 0 auto;
    width: 100%;
    height: 275px;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;

    & > .content {
        width: 1120px;
        height: 100%;
        margin: 0 auto;
        & > .introduce {
            height: 100%;
            float: left;

            & > div {
                & > h3 {
                    padding-top: 27px;
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                    color: #000000;
                }
                & > ul {
                    height: 18px;
                    width: 100%;
                    padding-top: 20px;
                    padding-bottom: 16px;

                    & > li {
                        position: relative;
                        display: table-cell;
                        vertical-align: middle;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 11px;
                    }
                }

                & > .copy {
                    padding-top: 6px;
                    margin-bottom: 55px;
                    & > p {
                        line-height: 24px;
                        font-weight: 300;
                        font-size: 13px;
                    }
                }

                & > .download {
                    margin-top: 36px;
                    & > a:nth-child(1) {
                        padding-right: 16px;
                    }
                    & > a{
                      & > img{
                        width: 118px;
                        height: 40px;
                        object-fit: contain;
                      }
                    }
                }
            }
        }

        & > .navigation {
            float: right;

            margin-top: 42px;

            & > .language {
                float: left;
                margin-left: 64px;
                & > div {
                    display: inline-block;
                    padding-right: 24px;
                    height: 24px;
                    & > a {
                        display: table;
                        height: 100%;
                        & > img,
                        span {
                            display: table-cell;
                            vertical-align: middle;
                        }
                        & > span {
                            padding-left: 8px;
                            line-height: 24px;
                        }
                        & > img {
                          width: 24px;
                          height: 24px;
                          object-fit: contain;
                        }
                    }
                }
            }

            & > .sns {
                float: left;
                & > div {
                    display: inline-block;
                    padding-right: 25px;
                    & > a {
                      & > img {
                        width: 24px;
                        height: 24px;
                        object-fit: contain;
                      }
                    }
                }
                & > div:last-child {
                    padding-right: 0;
                }
            }

            & > .f_navigation {
                display: block;
                margin-top: 36px;
                margin-bottom: 55px;
                & > ul {
                    display: block;
                    margin-right: 0;
                    & > li {
                        float: left;
                        margin-right: 78px;
                        & > div {
                            font-size: 13px;
                            font-weight: 300;
                            line-height: 32px;
                            & > a {
                                display: block;
                            }
                        }
                    }
                    & > li:last-child {
                        float: right;
                        margin-right: 0;
                    }
                }
                & > ul::after {
                    content: ''; display: block; clear: both;
                }
            }
        }
    }
}

@include media("<=medium", ">=small") {
    .m-footer {
        display: block;
    }
    .footer {
        display: none;
        max-width: 1440px;
        min-width: 1024px;
        margin: 0 auto;
        width: 100%;
        height: 275px;
        background-color: #ffffff;
        border-top: 1px solid #e0e0e0;

        & > .content {
            min-width: 1024px;
            max-width: 1440px;
            height: 100%;
            margin: 0 auto;
            & > .introduce {
                height: 100%;
                float: left;

                & > div {
                    & > h3 {
                        padding-top: 27px;
                        display: block;
                        font-size: 16px;
                        font-weight: 500;
                        color: #000000;
                    }
                    & > ul {
                        height: 18px;
                        display: table;
                        width: 100%;
                        padding-top: 16px;

                        & > li {
                            position: relative;
                            display: table-cell;
                            vertical-align: middle;
                            font-size: 13px;
                            line-height: 11px;
                        }
                    }

                    & > .copy {
                        padding-top: 6px;
                        margin-bottom: 55px;
                        & > p {
                            line-height: 24px;
                            font-weight: 300;
                            font-size: 13px;
                        }
                    }

                    & > .download {
                        margin-top: 36px;
                        & > a:nth-child(1) {
                            padding-right: 16px;
                        }
                    }
                }
            }

            & > .navigation {
                float: right;

                margin-top: 42px;

                & > .language {
                    float: left;
                    margin-left: 64px;
                    & > div {
                        display: inline-block;
                        padding-right: 24px;
                        height: 24px;
                        & > a {
                            display: table;
                            height: 100%;
                            & > img,
                            span {
                                display: table-cell;
                                vertical-align: middle;
                            }
                            & > span {
                                padding-left: 8px;
                                line-height: 24px;
                            }
                        }
                    }
                }

                & > .sns {
                    float: left;
                    & > div {
                        display: inline-block;
                        padding-right: 25px;
                    }
                }

                & > .f_navigation {
                    display: block;
                    margin-top: 36px;
                    margin-bottom: 55px;
                    & > ul {
                        display: table;
                        & > li {
                            float: left;
                            margin-right: 60px;
                            & > div {
                                font-size: 13px;
                                font-weight: 300;
                                line-height: 32px;
                                & > a {
                                    display: block;
                                }
                            }
                        }
                        & > li:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.footer_hidden {
    display: none;
}
