@import "styles/_Common.scss";

.compare {
  width: 1070px;
  margin: 0 auto;
  & > div {
    width: 1070px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    position: absolute;
    display: block;
    top: 0px;
    background-color: #fff;
    z-index: 11111;
    & > .compare-header {
      width: 1070px;
      min-width: 100%;
      height: 56px;
      background: #16bdc6;
      padding: 0 32px;
      display: table-cell;
      vertical-align: middle;
      & > p {
        font-weight: 500;
        font-size: 18px;
        color: #fff;
        float: left;
        line-height: 56px;
      }
      & > button {
        width: 40px;
        height: 56px;
        justify-content: center;
        align-items: center;
        position: relative;
        float: right;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-wrap: wrap;
        padding: 0 11px;
        left: 8px;

        & > .line {
          position: absolute;
          width: 18px;
          margin: 0px 0;
          border: 1px solid #fff;
          height: 0px;
          transform: rotateZ(45deg);
        }
        & > .line:nth-child(2) {
          transform: rotateZ(-45deg);
        }
      }
    }
    & > .compare-body {
      width: 100%;
      height: calc(85vh - 56px);
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 40px;
      &::-webkit-scrollbar {
        width: 8px;
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.4);
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      & > div {
        position: relative;
        & > div.body_head {
          position: fixed;
          top: 60px;
          width: 1070px;
          height: 60px;
          background-color: #fff;
          z-index: 999;
          padding-top: 10px;
        }
        & > div {
          position: relative;
          width: 100%;
          padding: 0 16px;
          margin: 8px 0;
          & > input[id="chk-all"] {
            display: none;
          }
          & > input[id="chk-all"] + label em {
            position: relative;
            display: inline-block;
            transform: translateY(50%);
            width: 16px;
            height: 16px;
            margin: 6px 0;
            margin-right: 16px;
            background: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 3px;
          }
          & > input[id="chk-all"]:checked + label em {
            background: #16bdc6;
          }
          & > label > em > img {
            position: absolute;
            top: 2px;
            left: 1px;
          }
          & > label > span {
            display: inline-block;
            height: 43px;
          }
          & > button {
            position: absolute;
            right: 16px;
            border: 1px solid #e0e0e0;
            padding: 11px 16px;
          }
          
        }
        &>.tableWrap{
          width: 1062px;
          height: 100%;
          overflow: scroll;
          padding-bottom: 40px;
          padding : 0 16px;
          margin-top: 60px;
          &::-webkit-scrollbar {
           display: none;
          }
          & > table {
            display: inline-block;
            border-spacing: 0;
            margin-top: 8px;
  
            border-collapse: collapse;
            & > tbody {
              display: block;
              & > tr:first-child {
                border-top: 1px solid rgba(0, 0, 0, 0.45);
                height: 253px;
                & > td {
                  height: 100%;
                  line-height: 1.6;
                }
              }
              & > tr {
                display: block;
                border: 0;
                border-bottom: 1px solid #e0e0e0;
                & > td:first-child {
                  background: #fafafa;
                  border-left: 1px solid #e0e0e0;
                  font-size: 14px;
                  width: 144px;
                }
                & > td {
                  float: left;
                  display: block;
                  border: 0;
                  border-right: 1px solid #e0e0e0;
                  width: 221px;
                  min-height: 48px;
                  height: auto;
                  line-height: 48px;
                  text-align: center;
                 
                  & > input {
                    display: none;
                  }
                  & > input + label em {
                    position: relative;
                    display: block;
                    width: 16px;
                    height: 16px;
                    margin: 8px;
                    margin-left: 8px;
                    background: #fff;
                    border: 1px solid #e0e0e0;
                    border-radius: 3px;
                  }
                  & > input:checked + label em {
                    background: #16bdc6;
                  }
                  & > label > em > img {
                    position: absolute;
                    top: 2px;
                    left: 1px;
                  }
                  & > img {
                    width: 90%;
                    margin: 0 auto;
                    margin-bottom: 4px;
                  }
                  & > h1 {
                    font-weight: 500;
                    font-size: 16px;
                    text-align: center;
                  }
                  & > p {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 48px;
                    min-width: 80px;
                    margin : 0;
                  }
                  & > span {
                    display: block;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: center;
                    margin-bottom: 15px;
                  }
                }
              }
              & > tr:nth-child(4) {
                & > td:first-child > p {
                  line-height: 60px;
                }
                & > td {
                  min-height: 60px; 
                  & > p {
                    line-height: 20px;
                  }
                }
              }
              & > tr:nth-child(5) {
                & > td:first-child > p {
                  line-height: 60px;
                }
                & > td {
                  min-height: 60px; 
                  & > p {
                    line-height: 20px;
                  }
                }
              }
              & > tr:nth-child(10) {
                & > td:first-child > p {
                  line-height: 60px;
                }
                & > td {
                  min-height: 60px; 
                  & > p {
                    line-height: 20px;
                  }
                }
              }
              & > tr:nth-child(11) {
                & > td:first-child > p {
                  line-height: 60px;
                }
                & > td {
                  min-height: 60px; 
                  & > p {
                    line-height: 20px;
                  }
                }
              }
              & > tr::after {
                content: ''; display: block; clear: both;
              }
            }
          }

        }
      }
    }
  }
  & > .compare-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 111;
  }
}

@include media("<=medium") {
  .compare {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999999999;
    & > div {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      & > .compare-body {
        width: 100%;
        height: calc(100% - 71px);
        padding: 0px;
        padding-bottom: 20px;
        overflow-y: scroll;
        & > div {
          overflow-x: scroll;
          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: none;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.4);
          }
          &::-webkit-scrollbar-track {
            background: none;
          }
          &>.tableWrap{
            margin-top: 60px;
          }
          & > div {
            &.body_head{
              position : fixed;
              top: 60px;
              width: 100%;
              height: 60px;
              background-color: #fff;
              z-index: 999;
              padding-top: 10px;
            }
            & > button {
              position: absolute;
              right: 20px;
              border: 1px solid #e0e0e0;
              padding: 11px 16px;
              
            }
          }
        }
      }
    }
  }
}
