@charset "UTF-8";
input, button {
  -webkit-appearance: none !important; }

polyline {
  color: white; }

path {
  color: white; }

.detail-all-wrap {
  width: 1120px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 60px; }
  .detail-all-wrap > .detail-wrap {
    max-width: 736px;
    display: inline-block; }
    .detail-all-wrap > .detail-wrap > .detail-content {
      width: 100%; }
      .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider {
        width: 100%;
        position: relative;
        margin-bottom: 15px;
        overflow: hidden; }
        .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider:hover > .btnWrap > div {
          display: block; }
        .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .btnWrap {
          width: 99%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 60px;
          line-height: 60px;
          overflow: hidden; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .btnWrap > div {
            background-color: rgba(255, 255, 255, 0.5);
            padding-top: 10px;
            text-align: center;
            display: none; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .btnWrap > .fl {
            float: left; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .btnWrap > .fr {
            float: right; }
      .detail-all-wrap > .detail-wrap > .detail-content > .house-content {
        width: 100%;
        /* margin-top: 46px;로 수정 */
        /* padding-top: 46px; 로 수정 */ }
        .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-category {
          width: 100%;
          z-index: 9999; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-category > button.select-btn {
            background: #4f4f4f;
            border-radius: 2px 0px 0px 2px;
            color: #fff; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-category > button {
            font-weight: 500;
            font-size: 14px;
            display: inline-block;
            background: #fafafa;
            border: 1px solid #e0e0e0;
            width: 25%;
            height: 50px; }
        .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner {
          position: relative;
          display: block;
          width: 100%;
          height: 200px;
          background-color: #16bdc6;
          text-align: center;
          margin-top: 46px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-rectangle {
            position: absolute;
            top: 24px;
            left: -20px;
            width: 128px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            background: #4f4f4f;
            box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.13);
            border-radius: 22px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-title {
            width: 100%;
            padding: 20px 0;
            font-size: 24px;
            color: #fff; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-content {
            width: 100%; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-content > div:first-child {
              margin-top: 0; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-content > div {
              display: block;
              margin: 10px 0;
              margin-left: 170px;
              text-align: left; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-content > div > span {
                font-size: 14px;
                font-weight: normal;
                color: #fff; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-content > div > span:first-child {
                margin-right: 20px; }
        .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information {
          max-width: 100%;
          padding-top: 46px;
          /* padding-top: 46px; 로 수정 */ }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > h1 {
            /* margin-bottom: 15px;로 수정 */
            margin-bottom: 15px;
            font-size: 18px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail {
            width: 100%;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 3px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail > div {
              width: calc(100% / 2);
              display: inline-block; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail > div > div {
                width: calc(100% / 2);
                padding: 24px 0;
                text-align: center;
                float: left; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail > div > div > span {
                  display: block;
                  margin-bottom: 16px;
                  color: rgba(0, 0, 0, 0.3); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail > div > div > h1 {
                  font-weight: 500;
                  font-size: 15px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more {
            display: table;
            table-layout: fixed;
            width: 100%; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row {
              display: table-row; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell:first-child {
                font-size: 14px;
                padding: 21px 8px 0;
                width: 137px;
                min-width: 137px;
                vertical-align: top; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell:first-child > span {
                  color: rgba(0, 0, 0, 0.3); }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell {
                border-bottom: 1px solid #e0e0e0;
                padding: 21px 0;
                width: calc(100% - 137px);
                display: table-cell;
                color: #323232;
                font-weight: 400;
                font-size: 14px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row {
                  width: 100%; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > div:first-child {
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: #000000;
                    vertical-align: top;
                    padding-right: 32px;
                    text-align: center;
                    width: 57px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > div:last-child {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: -8px; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > div:last-child > div > img {
                      width: 48px;
                      margin: 0 8px;
                      display: block;
                      height: auto; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > div:last-child > div > span {
                      display: block;
                      width: 100%;
                      text-align: center;
                      font-family: Noto Sans KR;
                      font-style: normal;
                      font-weight: 300;
                      font-size: 13px;
                      color: #323232;
                      padding-bottom: 16px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation {
            padding-top: 46px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 {
              font-size: 18px;
              /* margin-bottom: 15px; 로 수정 */
              margin-bottom: 11px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 > span {
                vertical-align: unset;
                cursor: pointer;
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                float: right; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 > span:last-child {
                margin-right: 12px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 > span[data-select="true"] {
                border-bottom: 1px solid #444444;
                padding-bottom: 10px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 > span[data-select="false"] {
                font-weight: 300;
                color: rgba(0, 0, 0, 0.3); }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h2.room-name-m {
              display: none; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table {
              border-top: 1px solid rgba(0, 0, 0, 0.3);
              border-bottom: 1px solid #e0e0e0; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > thead > tr > th {
                  padding: 14px 14px;
                  background: #fafafa;
                  font-size: 14px;
                  border-right: 1px solid #e0e0e0; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > thead > tr > th:last-child {
                  border: 0; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody > tr:first-child {
                  border-top: 1px solid #e0e0e0;
                  border-bottom: 1px solid #e0e0e0; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody > tr:first-child > td {
                    padding: 0 8px;
                    vertical-align: middle;
                    line-height: 48px;
                    text-align: center;
                    border-right: 1px solid #e0e0e0;
                    font-weight: 300;
                    font-size: 14px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody > tr:first-child > td:last-child {
                    border-right: none;
                    cursor: pointer; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody > tr:first-child > td:first-child {
                    vertical-align: middle;
                    line-height: 48px;
                    text-align: left; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody > tr:last-child > td,
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody tr:nth-child(2) > td {
                  padding: 0 8px;
                  vertical-align: middle;
                  line-height: 48px;
                  font-weight: 300;
                  font-size: 14px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody > tr:last-child > td:first-child,
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody tr:nth-child(2) > td:first-child {
                  color: rgba(0, 0, 0, 0.3); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody > tr:last-child > td:last-child,
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table > table > tbody tr:nth-child(2) > td:last-child {
                  padding: 0;
                  vertical-align: middle;
                  line-height: 48px;
                  text-align: left; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m {
              display: none; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young {
            /* padding-top: 46px;로 수정 */
            padding-top: 46px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > h1 {
              /* line-height: 1;로 수정 */
              line-height: 1;
              /* margin-bottom: 15px;로 수정 */
              margin-bottom: 15px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation {
              max-width: 100%;
              margin-bottom: 45px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation > .infomation-left {
                max-width: 160px;
                float: left; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation > .infomation-left > div {
                  padding-bottom: 19px;
                  border-bottom: 1px solid #e0e0e0;
                  font-weight: 300;
                  font-size: 14px;
                  margin-top: 15px;
                  cursor: pointer; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation > .infomation-left > div:first-child {
                  margin-top: 15px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation > .infomation-left > div[data-selected="true"] {
                  color: #16bdc6; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation > .infomation-left > .checked {
                  color: #16bdc6; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation > .infomation-right {
                max-width: calc(100% - 255px);
                float: left; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation > .infomation-right > img {
                  margin-left: 95px;
                  max-width: 100%;
                  display: none; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation > .infomation-right > .checked {
                  display: inline-block; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > h1 {
              font-size: 18px;
              margin-bottom: 15px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > h1 > span {
                margin-left: 15px;
                display: inline-block;
                vertical-align: unset;
                font-weight: 300;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65); }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div {
              width: 100%; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div.young_table {
                display: block; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div.young_table_m {
                display: none; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div > table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div > table > thead > tr {
                  border-top: 1px solid #e0e0e0;
                  border-bottom: 1px solid #e0e0e0; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div > table > thead > tr.view {
                    display: table-row; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div > table > thead > tr > th {
                    background-color: #fafafa;
                    padding: 16px 0;
                    border-right: 1px solid #e0e0e0;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.3); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div > table > thead > tr > th:last-child {
                    border-right: none; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div > table > tbody > tr > td {
                  /* font-size: 14px;로 수정 */
                  /* color: rgba(0, 0, 0, 0.65);로 수정 */
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.65);
                  padding: 15px 15px;
                  border-right: 1px solid #e0e0e0;
                  text-align: center;
                  vertical-align: middle;
                  border-top: 1px solid #e0e0e0;
                  border-bottom: 1px solid #e0e0e0; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > div > table > tbody > tr > td:last-child {
                  border-right: none;
                  cursor: pointer; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space {
            max-width: 100%;
            width: 100%; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > h1 {
              font-size: 18px;
              color: #000000;
              margin-bottom: 15px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info {
              width: 100%;
              border: 1px solid #e0e0e0;
              background-color: #fff;
              padding-bottom: 23px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info > h1 {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                margin-left: 24px;
                margin-top: 25px;
                margin-bottom: 8px;
                color: #000000; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info > p {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                margin-left: 24px;
                color: rgba(0, 0, 0, 0.3);
                margin-bottom: 20px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info > .target-ou {
                margin-left: 24px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info > span {
                font-family: Noto Sans KR;
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                color: #323232;
                margin-right: 24px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location {
            /* padding-top: 46px; 로 수정 */
            padding-top: 46px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location h1 {
              font-size: 18px;
              /* margin-bottom: 15px; 로 수정 */
              margin-bottom: 15px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-map {
              width: 736px;
              height: 375px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons {
              margin: 16px 0; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons > div {
                width: 100%; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons > div > .icons {
                  width: calc(100% / 14);
                  display: inline-block;
                  text-align: center; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons > div > .icons > img {
                    margin: 0 auto;
                    text-align: center;
                    display: block;
                    width: 32px;
                    height: 32px;
                    object-fit: contain; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons > div > .icons > span {
                    font-family: Noto Sans KR;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 8px;
                    line-height: 14px;
                    display: inline-block;
                    margin: 7px 0 20px 0;
                    white-space: nowrap; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .train-info {
              margin-top: 20px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .train-info > .info-items {
                padding: 5px 0;
                font-size: 14px;
                font-weight: 300; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .train-info > .info-items > span {
                  text-align: center;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  color: #ffffff;
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  margin-right: 8px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .train-info > .info-items > .string-train {
                  text-align: center;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  width: unset;
                  color: #ffffff;
                  display: inline-block;
                  padding: 0 6px;
                  height: 20px;
                  border-radius: 10px;
                  margin-right: 8px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .walk-info {
              font-weight: 300;
              font-size: 14px;
              line-height: 32px;
              margin-bottom: 15px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .schoolInfo > div {
              padding: 5px 0;
              font-size: 14px;
              font-weight: 300; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .schoolInfo > div > img {
                margin-right: 10px;
                display: inline-block;
                width: 16px;
                height: 16px;
                object-fit: contain; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .schoolInfo > div > span {
                margin-left: 10px;
                display: inline-block; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .schoolInfo > div > span:first-of-type {
                margin-left: 0; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .house-info {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 28px;
              color: #323232;
              margin-top: 20px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info {
            flex-wrap: wrap;
            max-width: 100%;
            width: 100%;
            padding: 20px;
            /* margin-top: 46px;로 수정 */
            margin-top: 46px;
            /* margin-bottom은 삭제 */
            border: 1px solid #e0e0e0;
            background: #ffffff; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .profile {
              display: inline-block;
              padding: 5px;
              margin-right: 20px;
              width: 75px;
              height: 75px;
              border-radius: 50%;
              overflow: hidden; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .profile > div > img {
                width: 100%;
                height: auto; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content {
              width: 573px;
              vertical-align: top;
              display: inline-block; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content > p {
                margin: 0;
                /* margin-bottom: 6px;로 수정 */
                margin-bottom: 6px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content > h1 {
                font-weight: 500;
                font-size: 20px;
                line-height: 29px;
                margin-bottom: 8px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content > p:last-child {
                font-weight: 300;
                /* font-size: 14px; 로 수정 */
                font-size: 14px;
                /* line-height: 24px; 로 수정 */
                line-height: 24px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story {
            position: relative;
            width: 100%;
            margin-left: -16px;
            height: 100%;
            /* margin-top, bottom: 46px;로 수정 */
            margin-top: 46px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > h1 {
              font-weight: 500;
              font-size: 20px;
              line-height: 29px;
              text-align: center;
              /* margin-bottom: 15px; 로 수정 */
              margin-bottom: 15px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > h1 > span {
                float: right;
                cursor: pointer;
                font-size: 13px;
                font-weight: 300;
                color: rgba(0, 0, 0, 0.3); }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider {
              position: relative;
              display: block;
              width: 100%;
              height: 100%;
              overflow: hidden; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner {
                width: 100%;
                overflow: hidden; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner:first-child > .story-card {
                  margin-left: 0; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner:last-child > .story-card {
                  margin-right: 0; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card {
                  float: left;
                  width: calc(100% / 3);
                  padding: 0 5px;
                  margin: 0 5px;
                  cursor: pointer; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-image {
                    margin-bottom: 17px;
                    position: relative; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-image > img {
                      width: 100%;
                      height: auto; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-image > .card-play {
                      width: auto;
                      position: absolute;
                      margin: auto;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      left: 0;
                      width: 48px;
                      height: 48px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-title {
                    height: 46px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    margin-bottom: 10px;
                    line-height: 23px;
                    overflow: hidden; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer {
                    width: 100%; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer > span {
                      font-weight: 300;
                      font-size: 12px;
                      vertical-align: middle;
                      line-height: 16px;
                      color: #828282;
                      height: 16px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer > span > img {
                        margin-right: 2px;
                        margin-bottom: -2px; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer > span:first-child {
                      margin-right: 90px; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer > span:nth-child(2) {
                      margin-right: 14px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-cards-inner[data-pos-location="0"] {
                  margin-left: calc(-33.333% * 0);
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-cards-inner[data-pos-location="1"] {
                  margin-left: calc(-33.333% * 1);
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-cards-inner[data-pos-location="2"] {
                  margin-left: calc(-33.333% * 2);
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-cards-inner[data-pos-location="3"] {
                  margin-left: calc(-33.333% * 3);
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-cards-inner[data-pos-location="4"] {
                  margin-left: calc(-33.333% * 4);
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-cards-inner[data-pos-location="5"] {
                  margin-left: calc(-33.333% * 5);
                  transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > h1.shareSpaceHead {
            font-size: 18px;
            /* line-height: 1;로 수정 */
            /* margin: 46px 0 15px; 로 수정 */
            line-height: 1;
            margin: 46px 0 15px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceHeadWrap {
            width: 100%; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceHeadWrap > div {
              /* line-height: 32px;로 수정 */
              line-height: 32px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceHeadWrap > div:nth-child(1) ~ * {
                margin-left: 10px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap {
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap::-webkit-scrollbar {
              height: 8px;
              background: none; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background: rgba(0, 0, 0, 0.3); }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap::-webkit-scrollbar-track {
              background: none; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList {
              width: 100%;
              overflow: hidden; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList .spaceContent {
                width: 50%;
                padding: 0 1.5%;
                float: left; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList .spaceContent > figure {
                  width: 100%;
                  overflow: hidden; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList .spaceContent > figure > img {
                    width: 100%;
                    object-fit: cover; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList .spaceContent > .contentText {
                  border: 1px solid #e0e0e0;
                  padding: 20px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList .spaceContent > .contentText > h4 {
                    font-size: 16px;
                    font-family: Noto Sans KR;
                    font-weight: 500; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList .spaceContent > .contentText > p {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.3);
                    margin-top: 10px;
                    min-height: 220px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList .spaceContent > .contentText > div {
                    margin-top: 10px;
                    font-weight: 300;
                    font-size: 14px; }
  .detail-all-wrap .right {
    max-width: 344px;
    flex: 1;
    height: 100vh;
    position: relative;
    right: 0;
    top: 0; }
  .detail-all-wrap .fixed-info {
    position: relative;
    top: 0;
    width: 344px;
    height: calc(100vh - 112px);
    transition: all 1s; }
    .detail-all-wrap .fixed-info > .tag-box > span {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.4); }
    .detail-all-wrap .fixed-info > h1 {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      height: 60px;
      border-bottom: 1px solid #e0e0e0;
      width: 344px;
      border-bottom: 1px solid #e0e0e0;
      margin-top: 10px;
      margin-bottom: 32px;
      padding-bottom: 32px; }
    .detail-all-wrap .fixed-info > p {
      font-size: 14px; }
    .detail-all-wrap .fixed-info > form > select {
      margin: 24px 0;
      width: 100%;
      height: 36px;
      background-color: #ffffff;
      border: 1px solid #e0e0e0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3); }
    .detail-all-wrap .fixed-info > form > input {
      background: #4f4f4f;
      border-radius: 4px;
      height: 48px;
      width: 100%;
      color: #ffffff;
      text-align: center; }
    .detail-all-wrap .fixed-info > .util-buttons {
      position: relative;
      margin-top: 24px; }
      .detail-all-wrap .fixed-info > .util-buttons::after {
        display: block;
        content: '';
        clear: both; }
      .detail-all-wrap .fixed-info > .util-buttons > div {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left; }
        .detail-all-wrap .fixed-info > .util-buttons > div > img {
          width: 22px;
          height: 22px;
          object-fit: contain; }
          .detail-all-wrap .fixed-info > .util-buttons > div > img.share {
            width: 28px;
            height: 28px; }
      .detail-all-wrap .fixed-info > .util-buttons > div:nth-child(3):hover ~ .hover-util {
        width: 100%;
        transition: 0.5s all;
        border: 1px solid #e0e0e0; }
      .detail-all-wrap .fixed-info > .util-buttons > .hover-util {
        border: none;
        transition: 0.5s all;
        background-color: #ffffff;
        position: absolute;
        max-width: 240px;
        width: 0%;
        overflow: hidden;
        left: 144px; }
        .detail-all-wrap .fixed-info > .util-buttons > .hover-util > button {
          margin: 0 11.5px; }
          .detail-all-wrap .fixed-info > .util-buttons > .hover-util > button > img {
            width: 30px;
            height: 30px;
            object-fit: contain; }
      .detail-all-wrap .fixed-info > .util-buttons > .hover-util:hover {
        border: 1px solid #e0e0e0;
        width: 100%; }
      .detail-all-wrap .fixed-info > .util-buttons > div:not(last-child) {
        margin-right: 8px; }
      .detail-all-wrap .fixed-info > .util-buttons > button {
        float: right;
        width: 104px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        border-radius: 2px; }
        .detail-all-wrap .fixed-info > .util-buttons > button > img {
          margin-right: 10px;
          width: 28px;
          height: 28px;
          object-fit: contain; }
    .detail-all-wrap .fixed-info > .util-buttons::after {
      content: "";
      display: block;
      clear: both; }

.t-cell {
  display: table-cell; }

.t-row {
  display: table-row; }

.slider-button {
  position: absolute;
  z-index: 999; }

.type-button {
  width: 80px;
  height: 32px;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  opacity: 0.7;
  position: absolute;
  top: 25px;
  left: 25px; }

.type-button:focus {
  opacity: 1; }

.type-button:hover {
  opacity: 1; }

.floor-button {
  width: 96px;
  height: 32px;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  opacity: 0.7;
  position: absolute;
  top: 25px;
  left: 113px; }

.floor-button:focus {
  opacity: 1; }

.floor-button:hover {
  opacity: 1; }

.room-selector {
  width: 96px;
  height: 32px;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  opacity: 0.7;
  position: absolute;
  top: 25px;
  left: 226px; }

.room-selector:focus {
  opacity: 1; }

.room-selector:hover {
  opacity: 1; }

.vr-see {
  width: 96px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  opacity: 0.9;
  position: absolute;
  top: 25px;
  left: 620px;
  line-height: 32px; }
  .vr-see > img {
    margin-top: -5px;
    vertical-align: middle;
    margin-right: 9px;
    width: 24px;
    height: 24px;
    object-fit: contain; }

.vr-see:focus {
  opacity: 1; }

.appender {
  border: none; }

.train-number {
  background: #1c66ce; }

.primary-blue-btn {
  width: 58px;
  height: 20px;
  background: #16bdc6;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 11px;
  margin-right: 8px; }

.nomination-btn {
  width: 30px;
  height: 20px;
  background: #fc9852;
  opacity: 0.8;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  line-height: 11px;
  margin-right: 5px; }

.chating {
  position: fixed;
  bottom: 10px;
  width: 344px;
  height: 455px;
  margin-top: 24px;
  border: 1px solid #e0e0e0;
  background: #fafafa;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); }
  .chating > .chating-head {
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    height: 72px;
    background-color: #16bdc6;
    position: relative; }
    .chating > .chating-head > h1 {
      font-size: 18px;
      color: #ffffff;
      padding-top: 15px;
      text-align: center; }
    .chating > .chating-head > p {
      margin-top: 4px;
      font-size: 14px;
      color: #fafafa;
      text-align: center; }
      .chating > .chating-head > p > span {
        font-size: 14px;
        color: #fafafa;
        text-align: center; }
        .chating > .chating-head > p > span:nth-child(2) {
          margin-left: 10px; }
        .chating > .chating-head > p > span > a {
          color: #fafafa; }
    .chating > .chating-head > div {
      position: absolute;
      transform: rotate(-45deg);
      top: 10px;
      right: 10px;
      font-size: 24px;
      color: #fff;
      cursor: pointer; }
  .chating > .date-selector {
    height: 64px;
    background: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    position: relative; }
    .chating > .date-selector > .cal {
      position: absolute;
      top: 60px;
      z-index: 100; }
    .chating > .date-selector > span {
      font-size: 13px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      margin-right: 10px; }
    .chating > .date-selector > input {
      width: 112px;
      height: 36px;
      background-color: #fff;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);
      border: 1px solid #e0e0e0;
      line-height: 36px;
      margin-left: 12px;
      text-indent: 10px; }
    .chating > .date-selector > .start-date::before {
      content: attr(data-placeholder);
      width: 100%; }
    .chating > .date-selector > input[type="date"]:focus::before,
    .chating > .date-selector > input[type="date"]:valid::before {
      display: none; }
  .chating > .chat-body {
    height: 244px;
    overflow-y: auto; }
    .chating > .chat-body::-webkit-scrollbar {
      width: 0;
      background: none; }
    .chating > .chat-body > .user-chat {
      margin: 24px 0;
      position: relative; }
      .chating > .chat-body > .user-chat > .chat {
        position: relative;
        margin-right: 24px;
        float: right;
        max-width: 212px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 6px 6px 0px 6px;
        white-space: normal;
        padding: 16px 12px;
        font-size: 14px;
        line-height: 22px; }
        .chating > .chat-body > .user-chat > .chat > .time-line {
          position: absolute;
          vertical-align: text-bottom;
          left: -70px;
          bottom: 0;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.3); }
    .chating > .chat-body > .admin-chat {
      margin: 24px 0;
      position: relative; }
      .chating > .chat-body > .admin-chat > .chat-profile {
        display: inline-block;
        margin-left: 16px;
        margin-right: 8px;
        vertical-align: top;
        width: 40px;
        height: 40px;
        border-radius: 50%; }
        .chating > .chat-body > .admin-chat > .chat-profile > img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
      .chating > .chat-body > .admin-chat > .chat {
        display: inline-block;
        position: relative;
        margin-right: 24px;
        max-width: 212px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0px 6px 6px 6px;
        white-space: normal;
        padding: 16px 12px;
        font-size: 14px;
        line-height: 22px; }
        .chating > .chat-body > .admin-chat > .chat > .time-line {
          position: absolute;
          vertical-align: text-bottom;
          right: -66px;
          bottom: 0;
          font-family: Noto Sans KR;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.3); }
  .chating > .chat-sender {
    background: #fafafa;
    padding-bottom: 16px;
    padding-top: 16px; }
    .chating > .chat-sender > form > input {
      margin-left: 12px;
      width: 276px;
      background: #ffffff;
      border: 1px solid #ecebed;
      border-radius: 18px;
      height: 36px;
      float: left;
      display: inline-block;
      padding-left: 27px;
      margin-right: 9px; }
    .chating > .chat-sender > form > .icon {
      width: 36px;
      height: 36px;
      background-image: url("../../../assets/images/Send.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      cursor: pointer; }

@media (max-width: 768px) and (min-width: 320px) {
  .detail-all-wrap {
    width: 100%;
    display: flex;
    margin-top: 0; }
    .detail-all-wrap > .detail-wrap {
      width: 100%;
      margin-top: 0px;
      display: inline-block; }
      .detail-all-wrap > .detail-wrap > .detail-content {
        width: 100%; }
        .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider {
          width: 100%;
          position: relative;
          margin-bottom: 24px;
          overflow: hidden; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner {
            position: static;
            margin-left: 0;
            width: 1000%;
            height: auto; }
            .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner > .inner-item > a > img {
              max-height: 300px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="0"] {
            margin-left: calc(-100% * 0);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="1"] {
            margin-left: calc(-100% * 1);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="2"] {
            margin-left: calc(-100% * 2);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="3"] {
            margin-left: calc(-100% * 3);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="4"] {
            margin-left: calc(-100% * 4);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="5"] {
            margin-left: calc(-100% * 5);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="6"] {
            margin-left: calc(-100% * 6);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="7"] {
            margin-left: calc(-100% * 7);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="8"] {
            margin-left: calc(-100% * 8);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="9"] {
            margin-left: calc(-100% * 9);
            transition: 0.5s all; }
          .detail-all-wrap > .detail-wrap > .detail-content > .detail-slider > .detail-slider-inner[data-pos-location="10"] {
            margin-left: calc(-100% * 10);
            transition: 0.5s all; }
        .detail-all-wrap > .detail-wrap > .detail-content > .thumb-changer {
          display: none;
          width: 100%;
          height: 75px;
          position: relative;
          overflow: hidden;
          margin-bottom: 40px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .thumb-changer > .thumb-changer-slider {
            position: absolute;
            height: 100%;
            width: calc(1000% + 11px * 10);
            left: 32px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .thumb-changer > .thumb-changer-slider > div {
              cursor: pointer;
              width: 96px;
              height: 72px;
              overflow: hidden;
              border-radius: 2px;
              margin-right: 11px;
              float: left; }
              .detail-all-wrap > .detail-wrap > .detail-content > .thumb-changer > .thumb-changer-slider > div > img {
                width: 100%;
                height: 100%; }
          .detail-all-wrap > .detail-wrap > .detail-content > .thumb-changer > .thumb-slide-left {
            cursor: pointer;
            width: 24px;
            height: 74px;
            background: #ffffff;
            opacity: 0.8;
            border: 1px solid #e0e0e0;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center; }
          .detail-all-wrap > .detail-wrap > .detail-content > .thumb-changer > .thumb-slide-right {
            cursor: pointer;
            z-index: 5;
            width: 24px;
            height: 74px;
            background: #ffffff;
            opacity: 0.8;
            right: 0;
            transform: rotateZ(180deg);
            border: 1px solid #e0e0e0;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center; }
        .detail-all-wrap > .detail-wrap > .detail-content > .house-content {
          width: 100%;
          padding: 0 16px; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-category {
            width: 100%;
            margin-bottom: 16px;
            z-index: 9999; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-category > button:select-btn {
              background-color: #4f4f4f;
              border-radius: 2px 0px 0px 2px;
              color: #fff; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-category > button:nth-child(4) {
              display: none; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-category > button {
              font-family: Noto Sans KR;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              display: inline-block;
              background: #fafafa;
              border: 1px solid #e0e0e0;
              height: 50px;
              width: 33.333%; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner {
            display: block;
            width: 100%;
            height: 100%;
            /* margin-bottom: 0;로 수정 */
            margin-bottom: 0px;
            background-color: #16bdc6; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-rectangle {
              width: 100%;
              left: -15px;
              width: 104px;
              height: 32px;
              line-height: 32px;
              font-size: 12px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-title {
              padding: 70px 20px 20px;
              font-size: 16px;
              font-weight: bold;
              letter-spacing: -0.4px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .content-event-banner > .banner-content > div {
              padding: 0px 40px 15px;
              margin: 0;
              font-size: 13px;
              letter-spacing: -0.4px;
              font-weight: normal; }
          .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information {
            max-width: 100%; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > h1 {
              margin-bottom: 15px;
              font-size: 18px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              border: 1px solid #e0e0e0;
              background-color: #fff;
              border-radius: 3px;
              padding: 8px 0; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail > div {
                width: 100%;
                display: inline-block; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail > div > div {
                  width: calc(100% / 2);
                  padding: 12px 0;
                  text-align: center;
                  float: left; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail > div > div > span {
                    display: block;
                    margin-bottom: 8px;
                    font-size: 13px;
                    color: rgba(0, 0, 0, 0.3); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail > div > div > h1 {
                    font-weight: 500;
                    font-size: 14px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more {
              display: table;
              table-layout: fixed;
              width: 100%; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row {
                display: table-row; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell:first-child {
                  color: rgba(0, 0, 0, 0.3);
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  padding: 21px 8px 0;
                  width: 75px;
                  min-width: 137px;
                  vertical-align: top; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell {
                  border-bottom: 1px solid #e0e0e0;
                  padding: 21px 0;
                  width: 100%;
                  display: table-cell;
                  color: #323232;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 12px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row {
                    width: 100%; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > .t-cell:first-child {
                      vertical-align: top;
                      padding-right: 32px;
                      display: none; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > .t-cell {
                      display: inline-block; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > .t-cell > img {
                        width: 48px;
                        height: 48px; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > div:last-child {
                      display: flex;
                      flex-wrap: wrap;
                      margin-top: -8px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > div:last-child > div {
                        width: 25%; }
                        .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > div:last-child > div > img {
                          width: 36px;
                          margin: 0 auto;
                          display: block;
                          height: auto; }
                        .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .info-detail-more > .t-row > .t-cell > .t-row > div:last-child > div > span {
                          display: block;
                          width: 100%;
                          text-align: center;
                          font-family: Noto Sans KR;
                          font-style: normal;
                          font-weight: 300;
                          font-size: 12px;
                          color: #323232;
                          padding-bottom: 16px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation {
              width: 100%;
              margin: 0 auto;
              /* margin-top: 0;로 수정*/
              margin-top: 0; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 {
                font-family: Noto Sans KR;
                font-style: normal;
                /* font-weight: normal; 삭제 = bold여야 함 */
                /* font-size: 18px로 수정; */
                font-size: 18px;
                margin-bottom: 11px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 > span {
                  display: inline-block;
                  vertical-align: unset;
                  cursor: pointer;
                  float: right; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 > span:last-child {
                  margin-right: 12px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 > span[data-select="true"] {
                  border-bottom: 1px solid #444444;
                  font-weight: bold;
                  font-size: 12px;
                  padding-bottom: 4px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h1 > span[data-select="false"] {
                  font-weight: 300;
                  font-size: 12px;
                  color: rgba(0, 0, 0, 0.3); }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h2.room-name-m {
                display: block;
                overflow-x: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h2.room-name-m::-webkit-scrollbar {
                  display: none; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h2.room-name-m > div {
                  width: 300px;
                  white-space: nowrap; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h2.room-name-m > div > span {
                    white-space: normal;
                    width: fit-content;
                    display: inline-block;
                    font-size: 12px;
                    line-height: 17px;
                    color: rgba(0, 0, 0, 0.3); }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h2.room-name-m > div > span.checked {
                      color: #333;
                      font-weight: bold; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > h2.room-name-m > div > span:nth-child(1) ~ * {
                      margin-left: 15px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table {
                display: none; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m {
                display: block;
                width: 100%;
                margin: 0 auto;
                border-top: 0px solid rgba(0, 0, 0, 0.3);
                overflow-x: scroll;
                overflow-y: hidden;
                scroll-snap-type: x mandatory; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table {
                  border-collapse: collapse;
                  border-spacing: 0; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table > tbody {
                    display: block;
                    float: left;
                    scroll-snap-align: start;
                    scroll-snap-stop: always; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table > tbody > tr:first-child {
                      border-top: 1px solid rgba(0, 0, 0, 0.3); }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table > tbody > tr {
                      border-bottom: 1px solid #e0e0e0;
                      display: block;
                      overflow: hidden; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table > tbody > tr > td {
                        padding: 0 8px;
                        vertical-align: middle;
                        line-height: 48px;
                        text-align: left;
                        font-weight: 300;
                        font-size: 12px;
                        display: block;
                        float: left; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table > tbody > tr > td:first-child {
                        vertical-align: middle;
                        line-height: 48px;
                        width: 25%;
                        border-right: 1px solid #e0e0e0;
                        color: rgba(0, 0, 0, 0.3);
                        background: #fafafa; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table > tbody > tr > td:last-child {
                        width: 75%; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table > tbody > tr > .fac {
                        line-height: 24px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table > div {
                    width: 100%;
                    font-size: 14px;
                    margin-top: 40px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="0"] {
                  width: calc(100% * 1); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="0"] > tbody {
                    width: calc(100% / 1); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="1"] {
                  width: calc(100% * 1); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="1"] > tbody {
                    width: calc(100% / 1); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="2"] {
                  width: calc(100% * 2); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="2"] > tbody {
                    width: calc(100% / 2); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="3"] {
                  width: calc(100% * 3); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="3"] > tbody {
                    width: calc(100% / 3); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="4"] {
                  width: calc(100% * 4); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="4"] > tbody {
                    width: calc(100% / 4); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="5"] {
                  width: calc(100% * 5); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="5"] > tbody {
                    width: calc(100% / 5); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="6"] {
                  width: calc(100% * 6); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="6"] > tbody {
                    width: calc(100% / 6); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="7"] {
                  width: calc(100% * 7); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="7"] > tbody {
                    width: calc(100% / 7); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="8"] {
                  width: calc(100% * 8); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="8"] > tbody {
                    width: calc(100% / 8); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="9"] {
                  width: calc(100% * 9); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="9"] > tbody {
                    width: calc(100% / 9); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="10"] {
                  width: calc(100% * 10); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="10"] > tbody {
                    width: calc(100% / 10); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="11"] {
                  width: calc(100% * 11); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="11"] > tbody {
                    width: calc(100% / 11); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="12"] {
                  width: calc(100% * 12); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="12"] > tbody {
                    width: calc(100% / 12); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="13"] {
                  width: calc(100% * 13); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="13"] > tbody {
                    width: calc(100% / 13); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="14"] {
                  width: calc(100% * 14); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="14"] > tbody {
                    width: calc(100% / 14); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="15"] {
                  width: calc(100% * 15); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="15"] > tbody {
                    width: calc(100% / 15); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="16"] {
                  width: calc(100% * 16); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="16"] > tbody {
                    width: calc(100% / 16); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="17"] {
                  width: calc(100% * 17); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="17"] > tbody {
                    width: calc(100% / 17); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="18"] {
                  width: calc(100% * 18); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="18"] > tbody {
                    width: calc(100% / 18); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="19"] {
                  width: calc(100% * 19); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="19"] > tbody {
                    width: calc(100% / 19); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="20"] {
                  width: calc(100% * 20); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation > .room-info-table-m > table[data-length="20"] > tbody {
                    width: calc(100% / 20); }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > h1 {
              font-size: 18px;
              margin-bottom: 11px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > h1 > span {
                margin-left: 15px;
                display: inline-block;
                vertical-align: unset;
                font-weight: 300;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65); }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m {
              max-width: 100%;
              margin-bottom: 10px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m > .infomation-left {
                width: 100%;
                max-width: none;
                float: none;
                display: flex;
                flex-flow: row nowrap;
                overflow-x: scroll;
                overflow-y: hidden; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m > .infomation-left > div {
                  padding-bottom: 5px;
                  border-bottom: 1px solid #e0e0e0;
                  font-weight: 300;
                  font-size: 14px;
                  margin-top: 15px;
                  cursor: pointer;
                  float: none;
                  width: fit-content;
                  height: 26px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  /* flex: 0 0 48%;로 수정 */
                  flex: 0 0 48%; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m > .infomation-left > div:nth-child(1) ~ * {
                    margin-left: 10px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m > .infomation-left > .checked {
                  color: #16bdc6; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m > .infomation-left > div:first-child {
                  margin-top: 15px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m > .infomation-left > div[data-selected="true"] {
                  color: #16bdc6; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m ul.slick-dots {
                display: none !important; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap {
                width: 100%; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap > img {
                  margin-left: 10px;
                  max-width: 100%;
                  width: 95%; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap > table {
                  width: 100%;
                  border-collapse: collapse;
                  border-spacing: 0;
                  margin-top: 20px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap > table > tbody {
                    width: 100%; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap > table > tbody > tr {
                      border: 1px solid #e0e0e0; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap > table > tbody > tr > th {
                        border: 1px solid #e0e0e0;
                        font-size: 12px;
                        background: #fafafa;
                        color: rgba(0, 0, 0, 0.3); }
                        .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap > table > tbody > tr > th:first-child {
                          width: 90px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap > table > tbody > tr > td {
                        font-size: 12px;
                        padding: 15px 15px;
                        border: 1px solid #e0e0e0;
                        text-align: center;
                        vertical-align: middle; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .room_infomation-young > .floor-infomation_m div.contentWrap > table > tbody > tr > td:last-child {
                        border-right: none;
                        cursor: pointer; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space {
              max-width: 100%;
              width: 100%; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > h1 {
                font-size: 18px;
                color: #000000;
                margin-bottom: 15px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info {
                width: 100%;
                border: 1px solid #e0e0e0;
                background-color: #fff;
                padding-bottom: 23px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info > h1 {
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  margin-left: 24px;
                  margin-top: 25px;
                  margin-bottom: 8px;
                  color: #000000; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info > p {
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  margin-left: 24px;
                  color: rgba(0, 0, 0, 0.3);
                  margin-bottom: 20px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info > .target-ou {
                  margin-left: 24px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .public-space > .space-info > span {
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 13px;
                  color: #323232;
                  margin-right: 24px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location h1 {
              font-size: 18px;
              margin-bottom: 15px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-map {
              width: 100%;
              height: 275px;
              margin-bottom: 0px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-map > img {
                max-width: 100%; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons {
              display: none; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons > div {
                max-width: 360px;
                width: 100%;
                margin-left: 100%;
                transform: translateX(-100%); }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons > div > .icons {
                  width: 20%; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons > div > .icons > span {
                    margin: 4px 0 12px 0; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .map-icons > div > .icons > img {
                    width: 32px;
                    height: 32px;
                    object-fit: contain; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .train-info > .info-items {
              vertical-align: middle;
              font-size: 14px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .train-info > .info-items > span {
                text-align: center;
                line-height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 6px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .walk-info {
              font-weight: 300;
              font-size: 14px;
              line-height: 32px;
              margin-bottom: 15px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .schoolInfo {
              font-size: 13px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-location > .house-location-info > .house-info {
              font-weight: 300;
              font-size: 14px;
              line-height: 32px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              width: 100%;
              padding: 0 16px;
              padding-top: 40px;
              /* margin-bottom: 0px;로 수정 */
              margin-bottom: 0px;
              text-align: center;
              border: 1px solid #e0e0e0;
              background: #ffffff; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .profile {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-right: 0px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .profile > div {
                  width: 72px;
                  height: 72px;
                  border-radius: 50%; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content {
                max-width: 400px;
                width: 100%;
                vertical-align: top;
                display: inline-block;
                padding-left: 0px;
                padding-bottom: 0px;
                margin-bottom: 32px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content > p {
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  margin-bottom: 16px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content > p:nth-child(2) {
                  margin-bottom: 16px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content > h1 {
                  margin-top: 16px;
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 29px;
                  margin-bottom: 7px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .master-info > .info-content > span {
                  margin: 0 24px;
                  float: left;
                  font-family: Noto Sans KR;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 17px; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story {
              width: 100%;
              margin-top: 32px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > h1 {
                font-weight: 500;
                font-size: 20px;
                line-height: 29px;
                text-align: center;
                /* margin-bottom: 15px;로 수정 */
                margin-bottom: 15px; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                overflow: scroll; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner {
                  width: 200%;
                  margin-left: 10px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card:first-child {
                    display: block; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card {
                    display: block;
                    width: calc(100% / 3);
                    padding: 0 16px;
                    float: left; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-image {
                      margin-bottom: 17px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-image > img {
                        width: 100%;
                        height: auto; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-title {
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 23px;
                      margin-bottom: 10px;
                      overflow: hidden; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer {
                      width: 100%; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer > span {
                        font-weight: 300;
                        font-size: 12px;
                        vertical-align: middle;
                        line-height: 16px;
                        color: #828282;
                        height: 16px; }
                        .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer > span > img {
                          margin-right: 2px;
                          margin-bottom: -2px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer > span:first-child {
                        margin-left: 0 !important;
                        margin-right: 90px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card > .card-footer > span:last-child {
                        margin-left: 14px; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner > .story-card:hover {
                    background-color: #f5f5f5;
                    cursor: pointer; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner[data-pos-location="0"] {
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner[data-pos-location="1"] {
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner[data-pos-location="2"] {
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner[data-pos-location="3"] {
                  transition: 0.5s all; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .house-story > .story-cards-slider > .story-cards-inner[data-pos-location="4"] {
                  transition: 0.5s all; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > h1 {
              font-size: 18px;
              /* line-height: 1;로 수정 */
              line-height: 1; }
            .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea {
              width: 100%; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceHeadWrap {
                width: 100%;
                overflow: hidden; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceHeadWrap > div {
                  float: left;
                  border-bottom: 1px solid #e0e0e0;
                  color: rgba(0, 0, 0, 0.3); }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceHeadWrap > div.checked {
                    color: #16bdc6; }
              .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap {
                width: 100%;
                margin-top: 20px; }
                .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList {
                  width: 100%;
                  display: flex;
                  flex-flow: row nowrap;
                  overflow-x: scroll;
                  overflow-y: hidden;
                  transition: all 0.3s;
                  scroll-snap-type: x mandatory; }
                  .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList > .spaceContent {
                    flex: 0 0 97%;
                    margin: 0 1.5%;
                    scroll-snap-align: start;
                    scroll-snap-stop: always; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList > .spaceContent > figure {
                      width: 100%;
                      overflow: hidden; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList > .spaceContent > figure > img {
                        width: 100%;
                        object-fit: cover; }
                    .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList > .spaceContent > .contentText {
                      border: 1px solid #e0e0e0;
                      padding: 15px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList > .spaceContent > .contentText > h4 {
                        font-size: 16px;
                        font-family: Noto Sans KR;
                        font-weight: 500;
                        line-height: 40px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList > .spaceContent > .contentText > p {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.3);
                        margin-top: 10px;
                        min-height: 220px; }
                      .detail-all-wrap > .detail-wrap > .detail-content > .house-content > .more-information > .shareArea > .spaceContentWrap > .spaceList > .spaceContent > .contentText > div {
                        margin-top: 10px;
                        font-weight: 300;
                        font-size: 14px; }
    .detail-all-wrap .fixed-info {
      display: none;
      max-width: 384px;
      width: 100%;
      float: right;
      margin-top: 81px;
      margin-left: 41px; }
      .detail-all-wrap .fixed-info > .tag-box > span {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.4); }
      .detail-all-wrap .fixed-info > h1 {
        font-weight: 500;
        font-size: 22px;
        padding-bottom: 11px;
        border-bottom: 1px solid #e0e0e0;
        width: 344px;
        border-bottom: 1px solid #e0e0e0;
        margin-top: 10px;
        margin-bottom: 31px; }
      .detail-all-wrap .fixed-info > .info-notice {
        font-size: 14px; }
      .detail-all-wrap .fixed-info > form > select {
        margin-top: 24px;
        width: 100%;
        height: 36px;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: 25px; }
      .detail-all-wrap .fixed-info > form > button {
        background: #4f4f4f;
        border-radius: 4px;
        height: 48px;
        width: 100%;
        color: #ffffff; }
      .detail-all-wrap .fixed-info > .util-buttons {
        position: relative;
        margin-top: 48px;
        margin-bottom: 100px; }
        .detail-all-wrap .fixed-info > .util-buttons > div {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border: 1px solid #e0e0e0;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          float: left; }
        .detail-all-wrap .fixed-info > .util-buttons > div:nth-child(3):hover ~ .hover-util {
          width: 100%;
          transition: 0.5s all;
          border: 1px solid #e0e0e0; }
        .detail-all-wrap .fixed-info > .util-buttons > .hover-util {
          border: none;
          transition: 0.5s all;
          background-color: #ffffff;
          position: absolute;
          max-width: 240px;
          width: 0%;
          overflow: hidden;
          left: 144px; }
          .detail-all-wrap .fixed-info > .util-buttons > .hover-util > a {
            margin: 0 11.5px; }
        .detail-all-wrap .fixed-info > .util-buttons > .hover-util:hover {
          border: 1px solid #e0e0e0;
          width: 100%; }
        .detail-all-wrap .fixed-info > .util-buttons > div:not(last-child) {
          margin-right: 8px; }
        .detail-all-wrap .fixed-info > .util-buttons > button {
          float: right;
          width: 104px;
          height: 36px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          border-radius: 2px; }
          .detail-all-wrap .fixed-info > .util-buttons > button > img {
            margin-right: 10px; }
  .t-cell {
    display: table-cell; }
  .t-row {
    display: table-row; }
  .type-button {
    display: none;
    width: 80px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #4f4f4f;
    border-radius: 4px;
    opacity: 0.9;
    position: absolute;
    top: 25px;
    left: 25px; }
  .type-button:focus {
    opacity: 1; }
  .floor-button {
    width: 96px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #4f4f4f;
    border-radius: 4px;
    opacity: 0.9;
    position: absolute;
    top: 25px;
    left: 113px;
    display: none; }
  .floor-button:focus {
    opacity: 1; }
  .room-selector {
    display: none;
    width: 96px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #4f4f4f;
    border-radius: 4px;
    opacity: 0.9;
    position: absolute;
    top: 25px;
    left: 226px; }
  .room-selector:focus {
    opacity: 1; }
  .vr-see {
    width: 96px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #4f4f4f;
    border-radius: 4px;
    opacity: 0.9;
    position: absolute;
    top: 25px;
    right: 25px;
    line-height: 32px; }
    .vr-see > img {
      margin-top: -5px;
      vertical-align: middle;
      margin-right: 9px; }
  .vr-see:focus {
    opacity: 1; }
  .appender {
    border: none; }
  .train-number1 {
    background: #1c66ce; }
  .primary-blue-btn {
    width: 58px;
    height: 20px;
    background: #16bdc6;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    line-height: 11px;
    margin-right: 8px; }
  .nomination-btn {
    width: 30px;
    height: 20px;
    background: #fc9852;
    opacity: 0.8;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    line-height: 11px;
    margin-right: 5px; }
  .chating {
    position: relative;
    width: 344px;
    border: 1px solid #e0e0e0;
    background: #fafafa;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); }
    .chating > .chating-head {
      border-radius: 4px 4px 0px 0px;
      width: 100%;
      height: 72px;
      background-color: #16bdc6; }
      .chating > .chating-head > h1 {
        font-size: 18px;
        color: #ffffff;
        padding-top: 15px;
        text-align: center; }
      .chating > .chating-head > p {
        margin-top: 4px;
        font-size: 12px;
        color: #fafafa;
        text-align: center; }
    .chating > .date-selector {
      height: 64px;
      background: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #e0e0e0; }
      .chating > .date-selector > span {
        font-size: 13px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 10px; }
      .chating > .date-selector > input {
        width: 90px;
        height: 36px;
        background-color: #fff;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        border: 1px solid #e0e0e0;
        line-height: 36px;
        padding-left: 12px; }
      .chating > .date-selector > .start-date::before {
        content: attr(data-placeholder);
        width: 100%; }
      .chating > .date-selector > input[type="date"]:focus::before,
      .chating > .date-selector > input[type="date"]:valid::before {
        display: none; }
    .chating > .chat-body {
      display: none; }
      .chating > .chat-body > .user-chat {
        margin: 24px 0;
        position: relative; }
        .chating > .chat-body > .user-chat > .chat {
          position: relative;
          margin-right: 24px;
          float: right;
          max-width: 212px;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 6px 6px 0px 6px;
          white-space: normal;
          padding: 10px 10px;
          font-size: 14px;
          line-height: 22px; }
          .chating > .chat-body > .user-chat > .chat > span {
            display: inline-block;
            width: 50%; }
          .chating > .chat-body > .user-chat > .chat > .time-line {
            position: absolute;
            left: -52px;
            top: 40px;
            font-family: "Noto Sans KR";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.3); }
      .chating > .chat-body > .admin-chat {
        margin: 24px 0;
        position: relative; }
        .chating > .chat-body > .admin-chat > .chat-profile {
          display: inline-block;
          margin-left: 16px;
          margin-right: 8px;
          vertical-align: top; }
        .chating > .chat-body > .admin-chat > .chat {
          display: inline-block;
          position: relative;
          margin-right: 24px;
          max-width: 212px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 0px 6px 6px 6px;
          white-space: normal;
          padding: 16px 12px;
          font-size: 14px;
          line-height: 22px; }
          .chating > .chat-body > .admin-chat > .chat > span {
            display: inline-block;
            width: 50%; }
          .chating > .chat-body > .admin-chat > .chat > .time-line {
            position: absolute;
            right: -52px;
            top: 40px;
            font-family: Noto Sans KR;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.3); }
    .chating > .chat-sender {
      background: #fafafa;
      padding-bottom: 16px;
      padding-top: 16px; }
      .chating > .chat-sender > input {
        margin-left: 12px;
        width: 276px;
        background: #ffffff;
        border: 1px solid #ecebed;
        border-radius: 18px;
        height: 36px;
        float: left;
        display: inline-block;
        padding-left: 27px;
        margin-right: 9px; }
      .chating > .chat-sender > .icon {
        width: 36px;
        height: 36px;
        background-image: url("../../../assets/images/Send.png");
        display: inline-block;
        cursor: pointer; } }

.gallery-description {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 24px;
  color: white; }

.gallery-item {
  width: 736px;
  height: 500px;
  object-fit: contain; }

.fullScreen-item {
  width: 80%;
  height: 1080px; }

@media (max-width: 768px) and (min-width: 320px) {
  .gallery-item {
    width: 100%;
    height: 300px; }
  .fullScreen-item {
    width: 100%;
    height: 100vh; }
  .gallery-description {
    font-size: 16px; } }

/* 해당위치로 스크롤가는 위치 수정 */
.comeToMe {
  width: 100%;
  height: 1px;
  transform: translateY(-46px); }
